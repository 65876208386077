<template>
  <div class="full-width full-height relative">
    <p style="font-size: 18px; color: #fff" @click="open">打开</p>
    <traffic-amap
      ref="amapIns"
      :baseTopAdcodes="regionCode"
      :baseTopAdcodesName="regionName"
      :data="cameraList2"
      @mapChange="mapChangeHandle"
      @openDialog="open"
      v-if="!$qiFang"
    ></traffic-amap>
    <qf-traffic-amap v-if="$qiFang" ref="amapIns" />
    <div class="absolute l20 b20" v-show="false">
      <div class="flex">
        <traffic-status
          className="online"
          @selectItem="selectItemHandle"
          :onlineStatus="classifyStatus"
          value="1"
        />
        <traffic-status
          className="offline"
          @selectItem="selectItemHandle"
          :onlineStatus="classifyStatus"
          value="0"
        />
        <traffic-status
          className="error"
          @selectItem="selectItemHandle"
          :onlineStatus="classifyStatus"
          value="2"
          v-if="!$g.isHideException"
        />
      </div>
    </div>
    <event-alarm-detail
      ref="eventAlarm"
      v-if="eventVisible"
      :visible="eventVisible"
      :cameraDetailId="cameraDetailId"
      :cameraDetail="cameraDetail"
      @close="closeDialog"
    />
  </div>
</template>

<script>
import TrafficAmap from "@/components/TrafficAmap3D";
import QfTrafficAmap from "@/components/QFTrafficAmap3D";
import { mapActions } from "vuex";
import { CODE_OK } from "@/request/config_code";
import { mercatorTolonlat } from "@/tools";
import { wgs84_to_gcj02 } from "@/tools/wgs84_to_gcj02.js";
import EventAlarmDetail from "@/components/EventAlarmDetail";
import TrafficStatus from "@/components/TrafficStatus";
export default {
  name: "TopMain",
  components: {
    TrafficAmap,
    QfTrafficAmap,
    TrafficStatus,
    EventAlarmDetail,
  },
  data() {
    return {
      regionName: window.g.title,
      regionCode: window.g.regionCode,
      cameraList2: [],
      classifyStatus: ["0", "1", "2"],
      eventVisible: false,
      cameraDetailId: "",
      cameraDetail: {},
    };
  },
  computed: {},
  watch: {},
  mounted() {
    setTimeout(() => {
      this._getPagePot();
      this._getEventAlarm();
      this._getEventAiList();
    }, 20);
  },
  methods: {
    ...mapActions(["getPagePot"]),
    /*  _getPagePot() {
      let mapInstance = this.$refs.amapIns;
      if (mapInstance) {
        let mymap = mapInstance.mapInstance,
          bounds = mymap.getBounds(),
          zoom = mymap.getZoom(),
          postBody = {
            cityCode: '',
            classify: '',
            orgnizationId: '',
            provinceCode: '',
            road: '',
          };
        let params = {
          params: {
            zoomLevel: zoom, //13,
            northEast: bounds.Rd[1].toString(),
            southWest: bounds.Rd[3].toString() 
          data: postBody,
        };
        this.getCameraList(params);
      }
    }, */
    /* 3d
            northEast: bounds.northeast.toString(),
            southWest: bounds.southwest.toString(), */
    _getPagePot() {
      let mapInstance = this.$refs.amapIns;
      if (mapInstance) {
        let mymap = mapInstance.mapInstance,
          // bounds = mymap.getBounds(),
          // zoom = mymap.getZoom(),
          zoom = "",
          bounds = {},
          northEast = "",
          southWest = "",
          postBody = {
            cityCode: "",
            classify: "",
            orgnizationId: "",
            provinceCode: "",
            road: "",
          };
        if (this.$qiFang) {
          let view = mymap.getView();
          // let center = view.getProjection();
          /*     let ext = center.getExtent(); */
          let mapSize = view.calculateExtent(mymap.getSize());
          let firstNode = mercatorTolonlat({ x: mapSize[0], y: mapSize[1] });
          var firstNodeLonLat = wgs84_to_gcj02(firstNode.x, firstNode.y);

          let secondNode = mercatorTolonlat({ x: mapSize[2], y: mapSize[3] });
          let secondNodeLonLat = wgs84_to_gcj02(secondNode.x, secondNode.y);
          zoom = view.getZoom();
          northEast = secondNodeLonLat.join(",");
          southWest = firstNodeLonLat.join(",");
        } else {
          bounds = mymap.getBounds();
          zoom = mymap.getZoom();
          northEast = bounds.Td[1].toString();
          southWest = bounds.Td[3].toString(); //3d
          // northEast = bounds.northeast.toString();
          // southWest = bounds.southwest.toString(); //非3d
        }
        let params = {
          params: {
            zoomLevel: zoom, //13,
            northEast,
            southWest /* 3d
            northEast: bounds.northeast.toString(),
            southWest: bounds.southwest.toString(), */,
          },
          data: postBody,
        };
        this.getCameraList(params);
      }
    },
    getCameraList(params) {
      this.getPagePot(params).then((res) => {
        this.copyCamera = res.data;
        this.cameraList = this.filterCamera(res.data);
      });
    },
    mapChangeHandle() {
      this._getPagePot();
    },
    open(e) {
      setTimeout(() => {
        this.cameraDetailId = e.cameraId;
        this.cameraDetail = e;
      }, 50);
      console.log("摄像机信息", e);
      this.eventVisible = true;
    },
    closeDialog() {
      this.eventVisible = false;
    },
    selectItemHandle(status) {
      // console.log(status);//["0","1"] 0 offline   1  online   2erro
      this.classifyStatus = status;
      let copyCamera = this.copyCamera.slice();
      // console.log(copyCamera);
      this.cameraList = this.filterCamera(copyCamera);
      // console.error('haha', this.cameraList);
    },
    filterCamera(cameraList) {
      let cameralist = cameraList.filter((item) => {
        if (this.classifyStatus.includes(item.onlineStatus)) {
          return true;
        }
      });
      // console.error('haah', this.classifyStatus, cameralist);
      return cameralist;
    },
    _getEventAlarm() {
      this.$api.aiCenterY.queryCamerasOnMap().then((res) => {
        if (res.code === CODE_OK) {
          let list = res.data.map((item) => {
            item.type = "1";
            let lonLat = wgs84_to_gcj02(item.longitude, item.latitude);
            item.long = lonLat[0];
            item.lat = lonLat[1];
            return item;
          });
          console.log("list", list);
          this.cameraList22 = [
            {
              hisId: null,
              lineNum: 0,
              startTime: null,
              roadCode: null,
              roadName: null,
              eventDetail: null,
              pileNum: null,
              parentEventName: null,
              eventSource: null,
              eventKey: null,
              eventName: null,
              direction: null,
              directionStr: null,
              cameraNum: "61D26875-3996-12CB-3A68-99C15E21327A",
              cameraName: null,
              isReport: null,
              eventSourceId: null,
              eventTime: null,
              longitude: "99.308719",
              latitude: "23.241681",
              mediaList: null,
              pictureList: null,
              videoList: null,
              organizationName: null,
            },
            {
              hisId: null,
              lineNum: 0,
              startTime: null,
              roadCode: null,
              roadName: null,
              eventDetail: null,
              pileNum: null,
              parentEventName: null,
              eventSource: null,
              eventKey: null,
              eventName: null,
              direction: null,
              directionStr: null,
              cameraNum: "58270e1b-d91a-4811-9d7f-e1a858a51af7",
              cameraName: null,
              isReport: null,
              eventSourceId: null,
              eventTime: null,
              longitude: "100.802762",
              latitude: "27.596339",
              mediaList: null,
              pictureList: null,
              videoList: null,
              organizationName: null,
            },
          ];
          // this.$nextTick(() => {
          //   this.$refs.amapIns && this.$refs.amapIns.pointsSetIn([...list, ...this.cameraMapList]);
          // });
          // this.setCameraMapList(data);
        }
      });
      // console.log('haha', this.$refs.amapIns, this.cameraMapList);
    },
    _getEventAiList() {
      this.$api.aiCenterY.queryAiAnalysep().then((res) => {
        if (res.code === CODE_OK) {
          this.cameraList2 = res.data.map((item) => {
            console.log("数据", JSON.parse(item.mediaList.replace(/'/g, "")));
            item.type = "1";
            let lonLat = wgs84_to_gcj02(item.longitude, item.latitude);
            item.long = lonLat[0];
            item.lat = lonLat[1];
            item.videoList = JSON.parse(item.mediaList).filter(
              (item) => item.type === "MP4"
            );
            item.pictureList = JSON.parse(item.mediaList).filter(
              (item) => item.type === "JPEG"
            );
            return item;
          });
          console.log("list", this.cameraList2);
        }
      });
    },
  },
  beforeDestroy() {
    this.$refs.amapIns && this.$refs.amapIns.destroyMap();
  },
};
</script>

<style scoped>
.l20 {
  left: 20px;
}
.b20 {
  bottom: 20px;
}
</style>
