<template>
  <div id="mapcontainer" class="full-width full-height"></div>
</template>

<script>
const AMap = window.AMap;
const AMapUI = window.AMapUI;

export default {
  name: "TrafficAmap3D",
  components: {},
  props: {
    baseTopAdcodes: {
      type: Number,
      default: 100000,
    },
    baseTopAdcodesName: {
      type: String,
      default: "中国",
    },
    data: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  data() {
    return {
      mapInstance: null,
      districtExplorer: null,
      currentAreaNode: null,
      beforeMarkers: null,
      eventVisible: false,
    };
  },
  watch: {
    data() {
      this.addMarker();
    },
  },
  mounted() {
    this.mapInit();
    document.oncontextmenu = function () {
      return false;
    };
  },
  methods: {
    mapSetCenter(pos) {
      // 传入经纬度，设置地图中心点
      let position = new AMap.LngLat(pos[0], pos[1]); // 标准写法
      this.mapInstance.setCenter(position);
    },
    /**
     * 地图初始化
     */
    mapInit() {
      this.$nextTick(() => {
        this.mapInstance = new AMap.Map("mapcontainer", {
          cursor: "default",
          resizeEnable: true,
          animateEnable: true,
          disableSocket: true,
          autoSetFitView: false,
          viewMode: "3D",
          //showLabel: false,//不显示地图文字标记
          labelzIndex: 130,
          topAdcodes: [this.baseTopAdcodes],
          pitch: 0,
          zoom: 3,
          mapStyle: "amap://styles/darkblue",
        });

        // 地图加载完成后触发
        this.mapInstance.on("complete", () => {
          this.initMapContent();
          //地图缩放
        });
        this.mapInstance.on("zoomend", () => {
          // console.error(e);
          this.$emit("mapChange");
        });
      });
    },
    /**
     * 加载区域
     */
    loadAreaNode(adcode, callback) {
      let _this = this;

      this.districtExplorer.loadAreaNode(adcode, function (error, areaNode) {
        //console.info('areaNode  ',adcode,areaNode);
        _this.districtExplorer.clearFeaturePolygons();
        if (error) {
          if (callback) {
            callback(error);
          }
          //console.error('loadAreaNode error ', error);
          return;
        }

        if (callback) {
          callback(null, areaNode);
        }
      });

      return false;
    },
    /**
     * 创建地图显示内容
     */
    initMapContent() {
      let that = this;

      that.mapInstance.AmbientLight = new AMap.Lights.AmbientLight(
        [1, 1, 1],
        0.3
      );
      that.mapInstance.DirectionLight = new AMap.Lights.DirectionLight(
        [0, 0, 1],
        [1, 1, 1],
        0.5
      );
      // 加载定位点
      this.addMarker();
      AMapUI.load(
        ["ui/geo/DistrictExplorer", "lib/$"],
        function (DistrictExplorer) {
          //创建一个实例
          let distExp = new DistrictExplorer({
            eventSupport: true, //打开事件支持
            map: that.mapInstance,
          });

          that.districtExplorer = distExp;

          //加载指定区块，默认全国
          that.switch2AreaNode(that.baseTopAdcodes);
        }
      );
    },

    /**
     * 切换区域
     */
    switch2AreaNode(adcode, callback) {
      //let currentAreaNode = null;
      if (
        this.currentAreaNode &&
        "" + this.currentAreaNode.getAdcode() === "" + adcode
      ) {
        return;
      }

      let that = this;
      this.loadAreaNode(adcode, function (error, areaNode) {
        if (error) {
          if (callback) {
            callback(error);
          }
          return;
        }

        that.currentAreaNode = areaNode; //this.currentAreaNodeBack =
        //设置当前使用的定位用节点
        that.districtExplorer.setAreaNodesForLocating([that.currentAreaNode]);
        that.refreshAreaNode(areaNode);
        if (callback) {
          callback(null, areaNode);
        }
      });
    },
    /**
     * 绘制某个区域的边界
     */
    renderAreaPolygons(areaNode) {
      //清除已有的绘制内容
      this.districtExplorer.clearFeaturePolygons();

      let fillColor = "rgba(2,6,11,1)",
        // this.mapInstance.add(this.googleLayer);
        // let fillColor = this.mapLayerType === 'google' ? 0 : this.googleLayer,
        strokeColor = "#2b3a7c", // '#0c3574',
        fillOpacity = 0.4;

      /* if (areaNode.adcode !== 100000) {
        //绘制子区域
        this.districtExplorer.renderSubFeatures(areaNode, function() {
          return {
            cursor: 'default',
            bubble: true,
            strokeColor: strokeColor, //线颜色
            strokeOpacity: 1, //线透明度
            strokeWeight: 1, //线宽
            fillColor: fillColor, //填充色
            fillOpacity: fillOpacity, //填充透明度
            zIndex: 1,
          };
        });
      } fixsxy*/
      if (areaNode.adcode !== 100000) {
        //绘制子区域
        this.districtExplorer.renderSubFeatures(areaNode, function () {
          return {
            cursor: "default",
            bubble: true,
            strokeColor: strokeColor, //线颜色
            strokeOpacity: 1, //线透明度
            strokeWeight: 1, //线宽
            fillColor: fillColor, //填充色
            fillOpacity: fillOpacity, //填充透明度
            zIndex: 1,
          };
        });
      }
      //绘制父区域
      this.districtExplorer.renderParentFeature(areaNode, {
        cursor: "default",
        bubble: true,
        strokeColor: strokeColor, //线颜色
        strokeOpacity: 1, //线透明度
        strokeWeight: 0, //线宽
        fillColor: fillColor, //填充色
        fillOpacity: 0.4, //填充透明度
        zIndex: 1,
      });
    },

    /**
     * 切换区域后刷新显示内容
     */
    refreshAreaNode(areaNode) {
      this.districtExplorer.setHoverFeature(null);

      //更新地图视野
      this.mapInstance.setBounds(areaNode.getBounds());
      this.renderAreaPolygons(areaNode);
    },

    addMarker() {
      // console.error('haha', this.data);
      if (this.beforeMarkers) {
        this.mapInstance.remove(this.beforeMarkers);
      }

      let that = this;
      let markList = this.data.map((item) => {
        /*  let marker = new AMap.Marker({
          position: new AMap.LngLat(item.longitude, item.latitude),
          content: '<div class="mapIconBgView"></div>',
          zIndex: 401,
        }); */
        let marker = new AMap.Marker({
          position: new AMap.LngLat(item.longitude, item.latitude),
          content: '<span class="mapIconBgView"></span>',
          offset: new AMap.Pixel(-1, -1),
          extData: { item },
          zIndex: 401,
        });
        console.log("marker点击事件222", item, marker);
        marker.on("click", function (e) {
          let item = e.target.getExtData().item;
          that.$emit("openDialog", item);
          console.log("marker点击事件", e, item);
          // alert(1)
          // this.eventVisible = true;
        });
        return marker;
      });
      let currentMarkers = new AMap.OverlayGroup(markList);
      this.beforeMarkers = currentMarkers;
      this.mapInstance.add(currentMarkers);
    },
    destroyMap() {
      this.mapInstance && this.mapInstance.destroy();
    },
  },
};
</script>
